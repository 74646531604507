import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './NavigationArrow.module.scss';
import { urlEscape } from '../../utils/helpers';

const NavigationArrow = ({ className, onClick, type, imgSrc }) => {
  let altText = 'Navigation arrow prev';
  let navigationClass = 'image-slider-previous';

  if (type === 'nextArrow') {
    altText = 'Navigation arrow next';
    navigationClass = 'image-slider-next';
  }

  return (
    <img
      onClick={onClick}
      className={classNames(
        className,
        styles.arrow,
        styles[type],
        navigationClass,
      )}
      src={urlEscape(imgSrc)}
      alt={altText}
    />
  );
};

NavigationArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['nextArrow', 'prevArrow']).isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default NavigationArrow;
