import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CustomPaging.module.scss';
import { urlEscape } from '../../../utils/helpers';

const CustomPaging = ({
  activeDotIndex,
  currentIndex,
  onClick,
  navigatorIcon,
  navigatorIconHover,
}) => {
  const [hover, toggleHover] = useState(false);

  if (hover) {
    navigatorIcon = navigatorIconHover;
  }
 
  return (
    <img
      onClick={onClick}
      className={styles.dotStyle}
      src={
        activeDotIndex === currentIndex
          ? urlEscape(navigatorIconHover)
          : urlEscape(navigatorIcon)
      }
      onMouseEnter={() => toggleHover(!hover)}
      onMouseLeave={() => toggleHover(!hover)}
      alt="Dot Navigator"
    />
  );
};

CustomPaging.propTypes = {
  activeDotIndex: PropTypes.number,
  currentIndex: PropTypes.number,
  onClick: PropTypes.func,
  navigatorIcon: PropTypes.string,
  navigatorIconHover: PropTypes.string,
};

export default CustomPaging;
