import { dummyImage } from '../constants';

const setEmptyImageSettings = images => {
    const emptyValuesSetImage = images.map(image => ({
        ...dummyImage,
        ...image,
    }));

    return emptyValuesSetImage;
};

export default setEmptyImageSettings;
