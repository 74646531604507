import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from 'sana/elements';

const Button = ({
  buttonText,
  buttonTextColor,
  buttonBackgroundColor,
  buttonBottomBorderColor,
  buttonBackgroundHoverColor,
  buttonBottomBorderHoverColor,
  buttonLink,
}) => {
  const [hover, toggleHover] = useState(false);

  let customStyle = {
    color: buttonTextColor && buttonTextColor,
    backgroundColor: buttonBackgroundColor && buttonBackgroundColor,
    boxShadow: buttonBottomBorderColor && `inset 0 -1px 0 ${buttonBottomBorderColor}`,
  };

  if (hover) {
    customStyle = {
      ...customStyle,
      backgroundColor: buttonBackgroundHoverColor && buttonBackgroundHoverColor,
      boxShadow: buttonBottomBorderHoverColor && `inset 0 -1px 0 ${buttonBottomBorderHoverColor}`,
    };
  }

  return buttonText ? (
    <LinkButton
      {...buttonLink}
      style={customStyle}
      onMouseEnter={() => toggleHover(!hover)}
      onMouseLeave={() => toggleHover(!hover)}
      className="image-slider-button-click"
    >
      {buttonText}
    </LinkButton>
  ) : null;
};

Button.propTypes = {
  buttonText: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonBottomBorderColor: PropTypes.string,
  buttonBackgroundHoverColor: PropTypes.string,
  buttonBottomBorderHoverColor: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default Button;
