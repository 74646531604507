const structureMiscellaneousSettings = ({
  transformAs,
  numbersOfImages,
  spaceBetweenImages,
}) => {
  const parsedTransformAs = parseInt(transformAs);

  const miscellaneousSettings = {
    fade: parsedTransformAs === 1 && true,
    slidesToShow: parsedTransformAs === 1 ? 1 : numbersOfImages,
    slidesToScroll: parsedTransformAs === 1 ? 1 : numbersOfImages,
  };

  return {
    miscellaneousSettings,
    spaceBetweenImages:
      numbersOfImages !== 1 && parsedTransformAs === 0 ? spaceBetweenImages : 0,
  };
};

export default structureMiscellaneousSettings;
