import React from 'react';
import PropTypes from 'prop-types';
import { renderHTML } from 'sana/utils';
import styles from './TitleVariant.module.scss';

const TitleVariant = ({ text, textColor }) => {
  return textColor ? (
    <div className={styles.textWrapper} style={{ color: textColor }}>
      {renderHTML(text)}
    </div>
  ) : (
      <div>
        {renderHTML(text)}
      </div>
    );
};

TitleVariant.propTypes = {
  text: PropTypes.string,
  textColor: PropTypes.string,
};

export default TitleVariant;