import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from './image/Image';
import ImageContent from './imageContent/ImageContent';
import styles from './ImageWrapper.module.scss';

const ImageWrapper = ({
  isMobile,
  imagePath,
  mobileImagePath,
  imageAlterText,
  mobileImageAlterText,
  titleText,
  titleTextColor,
  subtitleText,
  subtitleTextColor,
  buttonText,
  buttonTextColor,
  buttonBackgroundColor,
  buttonBottomBorderColor,
  buttonBackgroundHoverColor,
  buttonBottomBorderHoverColor,
  buttonLink,
  buttonPosition,
  spaceBetweenImages,
  imageScalingOption,
}) => {
  const imageSettings = {
    isMobile,
    imagePath,
    mobileImagePath,
    imageAlterText,
    mobileImageAlterText,
    spaceBetweenImages,
    imageScalingOption,
  };
  const contentSettings = {
    titleText,
    titleTextColor,
    subtitleText,
    subtitleTextColor,
    buttonText,
    buttonTextColor,
    buttonBackgroundColor,
    buttonBottomBorderColor,
    buttonBackgroundHoverColor,
    buttonBottomBorderHoverColor,
    buttonLink,
  };

  let contentPosition;
  const parsedButtonPosition = parseInt(buttonPosition);
  if (parsedButtonPosition === 0)
    contentPosition = styles.contentCenter;
  else if (parsedButtonPosition === 1)
    contentPosition = styles.contentLeft;
  else
    contentPosition = styles.contentRight;

  return (
    <div
      className={classNames(
        styles.imageWrapper,
        contentPosition,
        styles.contentPadding,
      )}
    >
      <Image {...imageSettings} />
      <ImageContent {...contentSettings} />
    </div>
  );
};

ImageWrapper.propTypes = {
  isMobile: PropTypes.bool,
  imagePath: PropTypes.string.isRequired,
  mobileImagePath: PropTypes.string,
  imageAlterText: PropTypes.string,
  mobileImageAlterText: PropTypes.string,
  titleText: PropTypes.string,
  titleTextColor: PropTypes.string,
  subtitleText: PropTypes.string,
  subtitleTextColor: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonBottomBorderColor: PropTypes.string,
  buttonBackgroundHoverColor: PropTypes.string,
  buttonBottomBorderHoverColor: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonPosition: PropTypes.number,
  spaceBetweenImages: PropTypes.number,
  imageScalingOption: PropTypes.number,
};

export default ImageWrapper;
