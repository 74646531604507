import React from 'react';
import NavigationArrow from '../../components/navigationArrow/NavigationArrow';

const structureNavigationButtonsSettings = navigationButtons => {
  const { showNavigation, previousIcon, nextIcon } = navigationButtons;

  const navigationSettings = {
    arrows: showNavigation,
    nextArrow: <NavigationArrow type="nextArrow" imgSrc={nextIcon} />,
    prevArrow: <NavigationArrow type="prevArrow" imgSrc={previousIcon} />,
  };

  return navigationSettings;
};

export default structureNavigationButtonsSettings;
