exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.22.60\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__NavigationArrow_arrow{height:40px!important;width:auto!important;z-index:1;opacity:.6;transition:.35s ease}.ImageSliderContentBlock__NavigationArrow_arrow:hover{opacity:1}.ImageSliderContentBlock__NavigationArrow_next-arrow{right:3%!important}.ImageSliderContentBlock__NavigationArrow_prev-arrow{left:3%!important}", ""]);

// exports
exports.locals = {
	"arrow": "ImageSliderContentBlock__NavigationArrow_arrow",
	"next-arrow": "ImageSliderContentBlock__NavigationArrow_next-arrow",
	"nextArrow": "ImageSliderContentBlock__NavigationArrow_next-arrow",
	"prev-arrow": "ImageSliderContentBlock__NavigationArrow_prev-arrow",
	"prevArrow": "ImageSliderContentBlock__NavigationArrow_prev-arrow"
};