import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getImageScalingClass } from './../../../utils/constants';
import styles from './Image.module.scss';

const Image = ({
  isMobile,
  imagePath,
  mobileImagePath,
  imageAlterText,
  mobileImageAlterText,
  spaceBetweenImages,
  imageScalingOption,
}) => {
  return (
    <img
      src={isMobile && mobileImagePath ? mobileImagePath : imagePath}
      alt={
        isMobile && mobileImagePath && mobileImageAlterText
          ? mobileImageAlterText
          : imageAlterText
      }
      className={classNames(
        styles.image,
        styles[getImageScalingClass(imageScalingOption)],
      )}
      style={{
        padding: spaceBetweenImages && `0 ${spaceBetweenImages}px`,
      }}
    />
  );
};

Image.propTypes = {
  isMobile: PropTypes.bool,
  imagePath: PropTypes.string.isRequired,
  mobileImagePath: PropTypes.string,
  imageAlterText: PropTypes.string.isRequired,
  mobileImageAlterText: PropTypes.string,
  spaceBetweenImages: PropTypes.number,
  imageScalingOption: PropTypes.number,
};

export default Image;
