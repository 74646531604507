import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import RenderSlider from './RenderSlider';
import styles from './ImageSlider.module.scss';
import './ImageSlider.scss';

const ImageSlider = ({ model, isDesignerMode }) => {
  const data = useMemo(() => {
    if (!model)
      return null;

    return {
      ...model,
      images: model.images
        .filter(i => i.visible)
        .sort(function (a, b) {
          return a.displayIndex - b.displayIndex;
        }),
    };
  }, [model]);

  if (!data)
    return null;

  return (
    <div className="image-slider-content-block image-slider-wrapper">
      {isDesignerMode && <div className={styles.vdMinHeight} />}
      <RenderSlider modelData={data} />
    </div>
  );
};

ImageSlider.propTypes = {
  model: PropTypes.object,
  isDesignerMode: PropTypes.bool,
};

export default React.memo(ImageSlider);
