const structureAutoPlaySettings = ({ rotationDelay, isAutoPlay }) => {
  const milisecSpeed = rotationDelay * 1000;

  const autoPlaySettings = {
    autoplay: isAutoPlay,
    autoplaySpeed: milisecSpeed,
  };

  return autoPlaySettings;
};

export default structureAutoPlaySettings;
