import React from 'react';
import { CustomPaging } from '../../components/dotNavigator';

const structureDotNavigatorIconSettings = (dotNavigator, activeDotIndex) => {
  const { navigatorIcon, navigatorIconOnHover } = dotNavigator;

  const dotNavigatorIconSettings = {
    customPaging: i => {
      return (
        <CustomPaging
          activeDotIndex={activeDotIndex}
          currentIndex={i}
          navigatorIcon={navigatorIcon}
          navigatorIconHover={navigatorIconOnHover}
        />
      );
    },
  };

  return dotNavigatorIconSettings;
};

export default structureDotNavigatorIconSettings;
